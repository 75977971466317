import React, { useState, useContext, useEffect } from "react";
import dayjs from 'dayjs';
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import "./InstallationPage.css";
import { FormContext } from "../../context/FormContext";
import { useTranslation } from "react-i18next";
import Buttons from "../../components/Buttons/Buttons";
import RadioButtonsGroup from "../../components/RadioButtons/RadioButtonsGroup";

import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import OptionSelect from "../../components/Select/OptionSelect";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import countryCodes from "../../countryCodes";

function InstallationPage() {
	const [formState, setFormState] = useState({
		company: "",
		address: "",
		phone: "",
		name: "",
		country: "",
		industry: "",
		installation: "",
		configuration: "",
		machinesTraining: "",
		webTraining: "",
		transport: "",
		machineInstallation: "",
		internetConnection: "",
		networkType: "",
		preferredLogin: "",
		cardReaderType: "",
		contactPerson: "",
		emailAddress: "",
		contactPersonPhone: "",
		machineTransfer: "",
		paymentMethod: "",
		subscriptionCost: "",
		rentalPeriod: [],
		rentDateTo: null,
		rentDateFrom: null,
		rentFormattedDateTo: "",
		rentFormattedDateFrom: "",
		expectedDeliveryDate: "",
		rawExpectedDeliveryDate: null,
	});

	const { formData, updateFormData } = useContext(FormContext);
	const [errors, setErrors] = useState({});

	const { t } = useTranslation();

	const isFieldValid = (fieldName) => {
		return !errors[fieldName];
	};

	const validateForm = () => {
		const newErrors = {};

		const requiredFields = [
			"country",
			"address",
			"company",
			"name",
			"phone",
			"industry",
			"installation",
			"internetConnection",
			"preferredLogin",
			"contactPerson",
			"emailAddress",
			"contactPersonPhone",
			"machineTransfer",
			"rawExpectedDeliveryDate"
		];

		if (formState.country && formState.country !== "PL") {
			requiredFields.push(
				"configuration",
				"webTraining",
				"machinesTraining",
				"transport",
				"machineInstallation"
			);
		}

		if (formState.internetConnection === "lan") {
			requiredFields.push("networkType");
		}
		if (formState.preferredLogin === "card") {
			requiredFields.push("cardReaderType");
		}
		if (formState.machineTransfer === "sale") {
			requiredFields.push("paymentMethod");
		}
		if (formState.machineTransfer === "rental") {
			requiredFields.push("rentDateTo", "rentDateFrom");
		}

		requiredFields.forEach((field) => {
			const fieldValue = formState[field];
			if (
				fieldValue === null ||
				fieldValue === undefined ||
				fieldValue === ""
			) {
				newErrors[field] = true;
			} else {
				newErrors[field] = false;
			}
		});

		if (!isValidEmail(formState.emailAddress)) {
			newErrors["emailAddress"] = true;
		}

		if (!isValidPhone(formState.contactPersonPhone)) {
			newErrors["contactPersonPhone"] = true;
		}

		if (!isValidPhone(formState.phone)) {
			newErrors["phone"] = true;
		}

		// Dodano walidację okresu wypożyczenia
		if (formState.rentDateFrom && formState.rentDateTo) {
			const rentStart = dayjs(formState.rentDateFrom);
			const rentEnd = dayjs(formState.rentDateTo);
			if (rentEnd.diff(rentStart, 'month', true) > 3) {
				newErrors['rentDateFrom'] = true;
				newErrors['rentDateTo'] = true;
			}
		}

		setErrors(newErrors);
		for (const key in newErrors) {
			if (newErrors[key]) {
				return false;
			}
		}

		return true;
	};

	const isValidEmail = (value) => {
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		return emailRegex.test(value);
	};

	const isValidPhone = (value) => {
		return value.replace(/\D/g, "").length > 0;
	};

	const switchInputError = (fieldName, value) => {
		setErrors((prevErrors) => ({
			...prevErrors,
			[fieldName]: value,
		}));
	};

	useEffect(() => {
		if (formData && formData.installationForm) {
			const installationForm = formData.installationForm;
			setFormState({
				...formState,
				company: installationForm.company || "",
				address: installationForm.address || "",
				phone: installationForm.phone || "",
				name: installationForm.name || "",
				country: installationForm.country || "",
				industry: installationForm.industry || "",
				installation: installationForm.installation || "",
				configuration:
					typeof installationForm.configuration === "boolean"
						? installationForm.configuration
						: "",
				machinesTraining:
					typeof installationForm.machinesTraining === "boolean"
						? installationForm.machinesTraining
						: "",
				webTraining:
					typeof installationForm.webTraining === "boolean"
						? installationForm.webTraining
						: "",
				internetConnection: installationForm.internetConnection || "",
				networkType: installationForm.networkType || "",
				preferredLogin: installationForm.preferredLogin || "",
				cardReaderType: installationForm.cardReaderType || "",
				contactPerson: installationForm.contactPerson || "",
				emailAddress: installationForm.emailAddress || "",
				contactPersonPhone: installationForm.contactPersonPhone || "",
				machineTransfer: installationForm.machineTransfer || "",
				transport: installationForm.transport || "",
				machineInstallation: installationForm.machineInstallation || "",
				rentalPeriod: installationForm.rentalPeriod || "",
				rentDateFrom: installationForm.rentDateFrom || null,
				rentDateTo: installationForm.rentDateTo || null,
				paymentMethod: installationForm.paymentMethod || "",
				subscriptionCost: installationForm.subscriptionCost || "",
				expectedDeliveryDate: installationForm.expectedDeliveryDate || "",
				rawExpectedDeliveryDate:
					installationForm.rawExpectedDeliveryDate || null,
				comment: installationForm.comment || "",
				rulesAccepted: installationForm.rulesAccepted || "",
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	useEffect(() => {
		if (formData?.installationForm) {
			const installationForm = formData.installationForm;

			if (installationForm.phone) {
				const phone = installationForm.phone;
				const countryCode = countryCodes.find(cc => phone.startsWith(cc.phoneCode))?.phoneCode || "+48";
				const number = phone.slice(countryCode.length);
				setPhoneCountryCode(countryCode);
				setPhoneNumber(number);
			}

			if (installationForm.contactPersonPhone) {
				const phone = installationForm.contactPersonPhone;
				const countryCode = countryCodes.find(cc => phone.startsWith(cc.phoneCode))?.phoneCode || "+48";
				const number = phone.slice(countryCode.length);
				setContactPhoneCountryCode(countryCode);
				setContactPhoneNumber(number);
			}
		}
	}, [formData]);

	const updateFormState = (name, value) => {
		setFormState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleButtonClick = () => {
		const installationForm = {
			...formState,
		};
		updateFormData({ installationForm });
	};

	const handleInputChange = (e) => {
		const { name, value, type, id } = e.target;
		let newValue = value;

		if (type === "radio" || name === "country" || name === "industry") {
			switchInputError(id || name, false);
		}

		if (value === "true" || value === "false") {
			newValue = value === "true";
		}

		setFormState({
			...formState,
			[name]: newValue,
		});
	};

	const handleInputBlur = (event) => {
		const { value, name } = event.target;
		let inputId = event.target.id;
		let isValid = true;

		if (value === "") {
			isValid = false;
		} else if (name === "phone" && !isValidPhone(value)) {
			isValid = false;
			inputId = "phone";
		}

		switchInputError(inputId, !isValid);
	};

	const handlePhoneBlur = (event) => {
		switchInputError("phone", !isValidPhone(event.target.value));
	};

	const getFormattedDate = (unformattedDate) => {
		const date = new Date(unformattedDate);
		const day = date.getDate().toString().padStart(2, "0");
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const year = date.getFullYear().toString();
		return `${day}.${month}.${year}`;
	};

	const updateExpectedDeliveryDate = (data) => {
		switchInputError("rawExpectedDeliveryDate", false);
		const formattedDate = getFormattedDate(data);
		setFormState({
			...formState,
			rawExpectedDeliveryDate: data,
			expectedDeliveryDate: formattedDate,
		});
	};


	const updateRentalPeriodFromDate = (data) => {
		switchInputError("rentDateFrom", false);
		const formattedDate = getFormattedDate(data);
		setFormState((prevState) => ({
			...prevState,
			rentDateFrom: data,
			rentFormattedDateFrom: formattedDate,
			rentDateTo: null, // Resetujemy rentDateTo przy zmianie rentDateFrom
			rentalPeriod: `${formattedDate} - ${prevState.rentFormattedDateTo}`,
		}));
	};

	const updateRentalPeriodToDate = (data) => {
		switchInputError("rentDateTo", false);
		const formattedDate = getFormattedDate(data);
		setFormState({
			...formState,
			rentDateTo: data,
			rentFormattedDateTo: formattedDate,
			rentalPeriod: `${formState.rentFormattedDateFrom} - ${formattedDate}`,
		});
	};

	const internetConnectionOptions = [
		{
			value: "lan",
			label: t("page.installation.radio.lan"),
		},
		{
			value: "gsm",
			label: t("page.installation.radio.gsm"),
		},
	];

	const preferredLoginOptions = [
		{
			value: "card",
			label: t("page.installation.radio.card"),
		},
		{
			value: "login",
			label: t("page.installation.radio.login"),
		},
		{
			value: "other",
			label: t("page.installation.radio.other"),
		},
	];

	const cardTypeOptions = [
		{
			value: "unique",
			label: t("page.installation.radio.unique"),
		},
		{
			value: "mifare",
			label: t("page.installation.radio.mifare"),
		},
		{
			value: "other",
			label: t("page.installation.radio.other"),
		},
		{
			value: "uncertain",
			label: t("page.installation.radio.uncertain"),
		},
	];

	const networkTypeOptions = [
		{
			value: "static",
			label: t("page.installation.radio.static"),
		},
		{
			value: "dynamic",
			label: t("page.installation.radio.dynamic"),
		},
	];

	const installationOptions = [
		{
			value: "new",
			label: t("page.installation.radio.new"),
		},
		{
			value: "reinstall",
			label: t("page.installation.radio.reinstall"),
		},
	];
	const transportOptions = [
		{
			value: "asd",
			label: t("page.installation.radio.asd"),
		},
		{
			value: "ownTransport",
			label: t("page.installation.radio.ownTransport"),
		},
	];
	const machineInstallationOptions = [
		{
			value: "asd",
			label: t("page.installation.radio.asd"),
		},
		{
			value: "ownInstallation",
			label: t("page.installation.radio.ownInstallation"),
		},
	];

	const machineTransferOptions = [
		{
			value: "sale",
			label: t("page.installation.radio.sale"),
		},
		{
			value: "rental",
			label: t("page.installation.radio.rental"),
		},
	];

	const paymentMethodOptions = [
		{
			value: "transfer",
			label: t("page.installation.radio.transfer"),
		},
		{
			value: "leasing",
			label: t("page.installation.radio.leasing"),
		},
	];

	const [phoneCountryCode, setPhoneCountryCode] = useState("+48");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [contactPhoneCountryCode, setContactPhoneCountryCode] = useState("+48");
	const [contactPhoneNumber, setContactPhoneNumber] = useState("");

	const formatPhoneNumber = (value) => {
		const number = value.replace(/\D/g, '');
		const chunks = number.match(/.{1,3}/g) || [];
		return chunks.join(' ');
	};

	const unformatPhoneNumber = (value) => {
		return value.replace(/\s/g, '');
	};

	return (
		<div className="installation-page">
			<section className="installation">
				<h2>{t("page.installation.header.installation")}</h2>
				<div className="installation-details">
					<OptionSelect
						label={t("page.installation.label.country")}
						placeholder={t("page.installation.placeholder.country")}
						value={formState.country}
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						isValid={isFieldValid("country")}
						options="country"
						name="country"
					/>
					<div
						className={`input-box ${!isFieldValid("address") ? "error" : ""}`}
						style={{ marginBottom: "64px" }}
					>
						<label htmlFor="address">
							{t("page.installation.label.address")}
						</label>
						<input
							id="address"
							type="text"
							name="address"
							value={formState.address}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							placeholder={t("page.installation.placeholder.address")}
						/>
						{!isFieldValid("address") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>

					<div
						className={`input-box ${!isFieldValid("company") ? "error" : ""}`}
					>
						<label htmlFor="company">
							{t("page.installation.label.company")}
						</label>
						<input
							id="company"
							type="text"
							name="company"
							placeholder={t("page.installation.placeholder.company")}
							value={formState.company}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
						/>
						{!isFieldValid("company") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>
					<div className={`input-box ${!isFieldValid("name") ? "error" : ""}`}>
						<label htmlFor="name">{t("page.installation.label.name")}</label>
						<input
							placeholder={t("page.installation.placeholder.name")}
							id="name"
							type="text"
							name="name"
							value={formState.name}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
						/>
						{!isFieldValid("name") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>

					<div className={`input-box ${!isFieldValid("phone") ? "error" : ""}`}>
						<label htmlFor="phone">{t("page.installation.label.phone")}</label>
						<div className="phone-input-container">
							<select
								id="phoneCountryCode"
								className="phone-country-code"
								value={phoneCountryCode}
								onChange={(e) => {
									setPhoneCountryCode(e.target.value);
									setFormState(prev => ({
										...prev,
										phone: `${e.target.value}${phoneNumber}`
									}));
								}}
								style={{ marginRight: "1rem" }}
							>
								{countryCodes.map((country) => (
									<option key={country.code} value={country.phoneCode}>
										{country.phoneCode}
									</option>
								))}
							</select>
							<input
								id="phone"
								type="text"
								className="phone-number"
								value={formatPhoneNumber(phoneNumber)}
								onChange={(e) => {
									const unformattedValue = unformatPhoneNumber(e.target.value);
									setPhoneNumber(unformattedValue);
									setFormState(prev => ({
										...prev,
										phone: `${phoneCountryCode}${unformattedValue}`
									}));
								}}
								onBlur={handlePhoneBlur}
							/>
						</div>
						{!isFieldValid("phone") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>
					<OptionSelect
						label={t("page.installation.label.industry")}
						placeholder={t("page.installation.placeholder.industry")}
						value={formState.industry}
						name="industry"
						options="industry"
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						isValid={isFieldValid("industry")}
					/>
				</div>

				<div className="radio-group">
					<RadioButtonsGroup
						id="installation"
						label={t("page.installation.label.installation")}
						options={installationOptions}
						value={formState.installation}
						handleChange={handleInputChange}
						isValid={isFieldValid("installation")}
					/>
					{formState.country && formState.country !== "PL" && (
						<Box className="conditional-radio-group">
							<RadioButtonsGroup
								id="configuration"
								label={t("page.installation.label.configuration")}
								value={formState.configuration}
								handleChange={handleInputChange}
								isValid={isFieldValid("configuration")}
							/>
							<RadioButtonsGroup
								id="machinesTraining"
								label={t("page.installation.label.machinesTraining")}
								value={formState.machinesTraining}
								handleChange={handleInputChange}
								isValid={isFieldValid("machinesTraining")}
							/>
							<RadioButtonsGroup
								id="webTraining"
								label={t("page.installation.label.webTraining")}
								value={formState.webTraining}
								handleChange={handleInputChange}
								isValid={isFieldValid("webTraining")}
							/>
							<RadioButtonsGroup
								id="transport"
								label={t("page.installation.label.transport")}
								options={transportOptions}
								value={formState.transport}
								handleChange={handleInputChange}
								isValid={isFieldValid("transport")}
							/>
							<RadioButtonsGroup
								id="machineInstallation"
								label={t("page.installation.label.machineInstallation")}
								options={machineInstallationOptions}
								value={formState.machineInstallation}
								handleChange={handleInputChange}
								isValid={isFieldValid("machineInstallation")}
							/>
						</Box>
					)}
				</div>
			</section>

			<section className="it-details">
				<h2 style={{ marginTop: "5.5rem" }}>{t("page.installation.header.it")}</h2>
				<div className="radio-group">
					<div className="input-box">
						<RadioButtonsGroup
							id="internetConnection"
							label={t("page.installation.label.internetConnection")}
							options={internetConnectionOptions}
							value={formState.internetConnection}
							handleChange={handleInputChange}
							isValid={isFieldValid("internetConnection")}
							tooltip={true}
						/>
						{formState.internetConnection === "gsm" && (
							<div className="primary-info">
								<InfoIcon style={{ width: "20px", height: "20px" }} />
								{t("page.installation.tooltip.cardsPin")}
							</div>
						)}
					</div>
					{formState.internetConnection === "lan" && (
						<RadioButtonsGroup
							id="networkType"
							label={t("page.installation.label.networkType")}
							options={networkTypeOptions}
							value={formState.networkType}
							handleChange={handleInputChange}
							isValid={isFieldValid("networkType")}
						/>
					)}
					<RadioButtonsGroup
						id="preferredLogin"
						label={t("page.installation.label.preferredLogin")}
						options={preferredLoginOptions}
						value={formState.preferredLogin}
						handleChange={handleInputChange}
						isValid={isFieldValid("preferredLogin")}
						groupSize={3}
					/>
					{formState.preferredLogin === "card" && (
						<div className="input-box">
							<RadioButtonsGroup
								id="cardReaderType"
								label={t("page.installation.label.cardType")}
								options={cardTypeOptions}
								value={formState.cardReaderType}
								handleChange={handleInputChange}
								isValid={isFieldValid("cardReaderType")}
								tooltip={true}
								groupSize={4}
							/>
							<div className="primary-info">
								<InfoIcon style={{ width: "40px" }} />
								{t("page.installation.tooltip.cardsSend")}
							</div>
						</div>
					)}
				</div>
				<div className="contact-details">
					<div
						className={`input-box ${!isFieldValid("contactPerson") ? "error" : ""
							}`}
					>
						<label htmlFor="contactPerson">
							{t("page.installation.label.contactPerson")}
						</label>
						<input
							name="contactPerson"
							id="contactPerson"
							type="text"
							placeholder={t("page.installation.placeholder.name")}
							value={formState.contactPerson}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
						/>
						{!isFieldValid("contactPerson") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>
					<div
						className={`input-box ${!isFieldValid("emailAddress") ? "error" : ""
							}`}
					>
						<label htmlFor="emailAddress">
							{t("page.installation.label.email")}
						</label>
						<input
							id="emailAddress"
							name="emailAddress"
							type="text"
							value={formState.emailAddress}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
						/>
						{!isFieldValid("emailAddress") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>
					<div
						className={`input-box ${!isFieldValid("contactPersonPhone") ? "error" : ""
							}`}
					>
						<label htmlFor="contactPersonPhone">
							{t("page.installation.label.contactPersonPhone")}
						</label>
						<div className="phone-input-container">
							<select
								id="contactPhoneCountryCode"
								className="phone-country-code"
								value={contactPhoneCountryCode}
								onChange={(e) => {
									setContactPhoneCountryCode(e.target.value);
									setFormState(prev => ({
										...prev,
										contactPersonPhone: `${e.target.value}${contactPhoneNumber}`
									}));
								}}
								style={{ marginRight: "1rem" }}
							>
								{countryCodes.map((country) => (
									<option key={country.code} value={country.phoneCode}>
										{country.phoneCode}
									</option>
								))}
							</select>
							<input
								id="contactPersonPhone"
								type="text"
								className="phone-number"
								value={formatPhoneNumber(contactPhoneNumber)}
								onChange={(e) => {
									const unformattedValue = unformatPhoneNumber(e.target.value);
									setContactPhoneNumber(unformattedValue);
									setFormState(prev => ({
										...prev,
										contactPersonPhone: `${contactPhoneCountryCode}${unformattedValue}`
									}));
								}}
								onBlur={handlePhoneBlur}
							/>
						</div>
						{!isFieldValid("contactPersonPhone") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>
				</div>
			</section>

			<section className="transfer">
				<h2>{t("page.installation.header.transfer")}</h2>
				<div className="radio-group">
					<RadioButtonsGroup
						id="machineTransfer"
						options={machineTransferOptions}
						value={formState.machineTransfer}
						handleChange={handleInputChange}
						isValid={isFieldValid("machineTransfer")}
					/>
					{formState.machineTransfer === "rental" && (
						<Box className="range-calendar-box" mb={5}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<Box>
									<DatePicker
										label={t("page.installation.placeholder.dateFrom")}
										value={formState.rentDateFrom}
										onChange={(value) => updateRentalPeriodFromDate(value)}
										format="DD-MM-YYYY"
										disablePast
									/>
									{!isFieldValid("rentDateFrom") && (
										<Typography mt={2} className="error-message">
											{t("page.installation.validation.global.required")}
										</Typography>
									)}
								</Box>
								<Box>
									<DatePicker
										label={t("page.installation.placeholder.dateTo")}
										value={formState.rentDateTo}
										onChange={(value) => updateRentalPeriodToDate(value)}
										format="DD-MM-YYYY"
										disablePast
										minDate={formState.rentDateFrom}
										maxDate={
											formState.rentDateFrom
												? dayjs(formState.rentDateFrom).add(3, "month")
												: undefined
										}
									/>
									{!isFieldValid("rentDateTo") && (
										<Typography mt={2} className="error-message">
											{t("page.installation.validation.global.required")}
										</Typography>
									)}
								</Box>
							</LocalizationProvider>
							<span className="side-text" style={{ width: "20rem" }}>
								/ {t("page.installation.tooltip.maximumDate")}
							</span>
							{(!isFieldValid("rentDateFrom") || !isFieldValid("rentDateTo")) && (
								<Typography mt={2} className="error-message">
									{t("page.installation.validation.rentalPeriodTooLong")}
								</Typography>
							)}
						</Box>
					)}
					{formState.machineTransfer === "sale" && (
						<RadioButtonsGroup
							id="paymentMethod"
							label={t("page.installation.label.paymentMethod")}
							options={paymentMethodOptions}
							value={formState.paymentMethod}
							handleChange={handleInputChange}
							isValid={isFieldValid("paymentMethod")}
						/>
					)}
				</div>
				<div className="transfer-details">
					<Box className="input-box" mb={5}>
						<label htmlFor="waitingTime">
							{t("page.installation.label.expectedDeliveryDate")}
						</label>
						<Box className="calendar-box">
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									label={t("page.installation.placeholder.date")}
									value={formState.rawExpectedDeliveryDate}
									onChange={(value) => updateExpectedDeliveryDate(value)}
									format="DD-MM-YYYY"
									disablePast
								/>
							</LocalizationProvider>
							<span className="side-text" style={{ width: "20rem" }}>
								/ {t("page.installation.tooltip.dateConfirmation")}
							</span>
						</Box>
						<div className="primary-info">
							<InfoIcon style={{ width: "40px" }} />
							{t("page.installation.tooltip.dateStorage")}
						</div>
						{!isFieldValid("rawExpectedDeliveryDate") && (
							<Typography className="date-error error-message">
								{t("page.installation.validation.global.required")}
							</Typography>
						)}
					</Box>

				</div>
			</section>
			<Buttons
				validateForm={validateForm}
				handleButtonClick={handleButtonClick}
			></Buttons>
		</div>
	);
}

export default InstallationPage;
