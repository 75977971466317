import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import download from 'downloadjs';

export const generatePDF = async (drumConfigurations, formData, translations) => {
  const {
    contactForm,
    machinesForms,
    installationForm,
    billingForm,
    IDSBillingForm,
    summaryForm,
  } = formData;

  const pdfDoc = await PDFDocument.create();
  const fontBytes = await fetch('/Montserrat-Regular.ttf').then((response) =>
    response.arrayBuffer()
  );
  const fontBytesBold = await fetch('/Montserrat-Bold.ttf').then((response) =>
    response.arrayBuffer()
  );

  // Load logo image
  const logoBytes = await fetch('/logo.png').then((response) =>
    response.arrayBuffer()
  );
  const logoImage = await pdfDoc.embedPng(logoBytes);
  const logoDims = logoImage.scale(0.3); // Scale logo to 30% of original size

  pdfDoc.registerFontkit(fontkit);
  const customFontRegular = await pdfDoc.embedFont(fontBytes);
  const customFontBold = await pdfDoc.embedFont(fontBytesBold);

  // Function to get translation for a given key
  function getTranslation(path, key) {
    if (key === '' || key === null || key === undefined) {
      key = 'nodatafound';
    }

    let pathElements = path.split('.');

    let obj = translations;
    for (let element of pathElements) {
      obj = obj[element];
    }

    return obj[key] || obj['nodatafound'];
  }

  const forms = {
    contact: contactForm,
    billing: billingForm,
    IDSBilling: IDSBillingForm,
    installation: installationForm,
    summary: summaryForm,
  };

  const translatableFields = [
    'color',
    'barcodeReaderOption',
    'barcodeReaderType',
    'drumScanner',
    'panelSide',
    'plexiglassWindows',
    'logoOnDisplay',
    'internetConnection',
    'networkType',
    'preferredLogin',
    'cardReaderType',
    'installation',
    'configuration',
    'machinesTraining',
    'webTraining',
    'transport',
    'machineInstallation',
    'machineTransfer',
    'paymentMethod',
    'country',
    'industry',
  ];

  const translatableNoDataFoundFields = ['rentalPeriod'];

  const formFields = {
    contact: ['name', 'company', 'address', 'nip', 'email', 'phone'],
    billing: ['billingCompany', 'billingAddress', 'billingNip'],
    IDSBilling: ['IDSBillingCompany', 'IDSBillingAddress', 'IDSBillingNip'],
    installation: {
      installation: ['country', 'address', 'company', 'name', 'phone', 'industry', 'installation'],
      services: ['configuration', 'machinesTraining', 'webTraining', 'transport', 'machineInstallation'],
      transfer: ['machineTransfer', 'paymentMethod', 'rentalPeriod', 'expectedDeliveryDate'],
      it: ['internetConnection', 'networkType', 'preferredLogin', 'cardReaderType', 'contactPerson', 'emailAddress', 'contactPersonPhone'],
    },
    summary: ['comment'],
    machines: [
      'machineType',
      'version',
      'barcodeReaderOption',
      'barcodeReaderType',
      'drumScanner',
      'panelSide',
      'plexiglassWindows',
      'logoOnDisplay',
      'color',
      'rightDoorDetail',
      'leftDoorDetail',
      'rightCaseDetail',
      'leftCaseDetail',
      'backCaseDetail',
      'topCaseDetail',
    ],
  };

  const fields = {};

  Object.keys(forms).forEach((formName) => {
    const form = forms[formName];

    if (formName === 'installation') {
      fields[formName] = {};
      const subSections = formFields[formName];
      Object.keys(subSections).forEach((subSectionName) => {
        const fieldNames = subSections[subSectionName];
        fields[formName][subSectionName] = fieldNames.map((fieldName) => {
          if (translatableFields.includes(fieldName)) {
            return {
              label: translations[formName].label[fieldName],
              value: getTranslation(`${formName}.data`, form[fieldName]),
              translationKey: `${formName}.label.${fieldName}`,
            };
          } else if (translatableNoDataFoundFields.includes(fieldName) && form[fieldName] === '') {
            return {
              label: translations[formName].label[fieldName],
              value: getTranslation(`${formName}.data`, form[fieldName]),
              translationKey: `${formName}.label.${fieldName}`,
            };
          } else {
            return {
              label: translations[formName].label[fieldName],
              value: form[fieldName],
              translationKey: `${formName}.label.${fieldName}`,
            };
          }
        });
      });
    } else {
      const fieldNames = formFields[formName];
      fields[formName] = fieldNames.map((fieldName) => {
        if (translatableFields.includes(fieldName)) {
          return {
            label: translations[formName].label[fieldName],
            value: getTranslation(`${formName}.data`, form[fieldName]),
            translationKey: `${formName}.label.${fieldName}`,
          };
        } else {
          return {
            label: translations[formName].label[fieldName],
            value: form[fieldName],
            translationKey: `${formName}.label.${fieldName}`,
          };
        }
      });
    }
  });

  const splitTextIntoLines = (text, maxWidth, font, fontSize) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    words.forEach((word) => {
      const lineWithWord = currentLine ? `${currentLine} ${word}` : word;
      const lineWidth = font.widthOfTextAtSize(lineWithWord, fontSize);

      if (lineWidth <= maxWidth) {
        currentLine = lineWithWord;
      } else {
        if (currentLine) {
          lines.push(currentLine);
        }
        currentLine = word;
      }
    });

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };

  // Function to draw a field
  const drawField = (page, formStartY, cellHeight, pageWidth, field, labelWidth) => {
    let startY = formStartY;
    const maxValueWidth = pageWidth - pagePadding * 2 - labelWidth - 4;

    // Split value into lines and calculate height
    const valueLines = splitTextIntoLines(field.value || '', maxValueWidth, customFontRegular, fontSize);
    const numValueLines = valueLines.length;
    const valueHeight = numValueLines * (fontSize + 4); // value text height

    // Set cell height as maximum of default height and value height
    const adjustedCellHeight = Math.max(cellHeight, valueHeight);

    // Draw label (key)
    page.setFont(customFontRegular);
    page.setFontSize(fontSize);
    page.drawText(`${field.label}: `, {
      x: pagePadding + 2,
      y: startY - fontSize - 2,
      color: rgb(0, 0, 0),
    });

    // Draw value (wrapped text)
    valueLines.forEach((line, index) => {
      page.drawText(line, {
        x: pagePadding + labelWidth + 2,
        y: startY - (fontSize + 2) * (index + 1),
        color: rgb(0, 0, 0),
        size: fontSize,
      });
    });

    // Draw table lines (borders)
    // Left line
    page.drawLine({
      start: { x: pagePadding, y: startY },
      end: { x: pagePadding, y: startY - adjustedCellHeight },
      thickness: 0.5,
      color: rgb(196 / 255, 196 / 255, 196 / 255),
    });
    // Right line
    page.drawLine({
      start: { x: pageWidth - pagePadding, y: startY },
      end: { x: pageWidth - pagePadding, y: startY - adjustedCellHeight },
      thickness: 0.5,
      color: rgb(196 / 255, 196 / 255, 196 / 255),
    });
    // Vertical line between label and value
    page.drawLine({
      start: { x: pagePadding + labelWidth, y: startY },
      end: { x: pagePadding + labelWidth, y: startY - adjustedCellHeight },
      thickness: 0.5,
      color: rgb(196 / 255, 196 / 255, 196 / 255),
    });
    // Bottom line
    page.drawLine({
      start: { x: pagePadding, y: startY - adjustedCellHeight },
      end: { x: pageWidth - pagePadding, y: startY - adjustedCellHeight },
      thickness: 0.5,
      color: rgb(196 / 255, 196 / 255, 196 / 255),
    });

    // Return new Y position, moved by cell height
    return startY - adjustedCellHeight;
  };

  // Function to draw fields for a form or sub-section
  const drawFieldsForForm = (formName, page, formStartY, cellHeight, pageWidth, fields, labelWidth, index = null) => {
    // Section header
    page.setFont(customFontBold);
    let startX = pagePadding;
    page.setFontSize(fontSize);

    // Modify the header text to include the index if provided
    const headerText = index !== null
      ? `${translations[formName].header} ${index + 1}`
      : `${translations[formName].header}`;

    page.drawText(headerText, {
      x: startX,
      y: formStartY + 4,
      color: rgb(132 / 255, 132 / 255, 132 / 255),
    });

    page.setFont(customFontBold);
    let startY = formStartY - cellHeight;
    let tableStartY = startY + 14;

    // Top line
    page.drawLine({
      start: { x: pagePadding, y: tableStartY },
      end: { x: pageWidth - pagePadding, y: tableStartY },
      thickness: 0.5,
      color: rgb(196 / 255, 196 / 255, 196 / 255),
    });

    fields[formName].forEach((field) => {
      startY = drawField(page, formStartY, cellHeight, pageWidth, field, labelWidth);
      formStartY = startY;
    });

    return startY;
  };

  // Function to draw fields for installation sub-sections
  const drawFieldsForSubSections = (formName, page, formStartY, cellHeight, pageWidth, fields, labelWidth) => {
    const subSections = fields[formName];
    const headers = translations[formName].headers;

    Object.keys(subSections).forEach((subSectionName, index) => {
      if (index > 0) {
        formStartY -= 24; // 12 px gap between tables
      }
      // Sub-section header
      page.setFont(customFontBold);
      page.setFontSize(fontSize);
      page.drawText(`${headers[subSectionName]}`, {
        x: pagePadding,
        y: formStartY + 4,
        color: rgb(132 / 255, 132 / 255, 132 / 255),
      });

      let startY = formStartY - cellHeight;
      let tableStartY = startY + 14;

      // Top line
      page.drawLine({
        start: { x: pagePadding, y: tableStartY },
        end: { x: pageWidth - pagePadding, y: tableStartY },
        thickness: 0.5,
        color: rgb(196 / 255, 196 / 255, 196 / 255),
      });

      subSections[subSectionName].forEach((field) => {
        startY = drawField(page, formStartY, cellHeight, pageWidth, field, labelWidth);
        formStartY = startY;
      });
    });

    return formStartY;
  };

  const pagePadding = 30; // Padding on all sides of the page
  const fontSize = 7;

  // Create the first page with all information except machines
  const page = pdfDoc.addPage();
  const { width, height } = page.getSize();
  page.setFont(customFontRegular);

  drawHeaderElements(page, height, logoImage, logoDims, width, pagePadding, customFontRegular, translations);

  let formStartY = height - pagePadding - logoDims.height - 30; // Adjust starting position to account for logo
  const cellHeight = 14; // Adjust as needed

  formStartY = drawFieldsForForm('contact', page, formStartY, cellHeight, width, fields, 75);
  formStartY = drawFieldsForForm('billing', page, formStartY - 24, cellHeight, width, fields, 75);
  formStartY = drawFieldsForForm('IDSBilling', page, formStartY - 24, cellHeight, width, fields, 75);
  formStartY -= 24; // Space before installation sections

  // Draw installation sub-sections
  formStartY = drawFieldsForSubSections('installation', page, formStartY, cellHeight, width, fields, 210);

  // Draw the summary comment
  if (summaryForm.comment) {
    formStartY -= 30; // Add some space before the comment
    page.setFont(customFontBold);

    page.drawText(`${translations.summary.label.comment}:`, {
      x: pagePadding,
      y: formStartY,
      color: rgb(132 / 255, 132 / 255, 132 / 255),
    });
    formStartY -= cellHeight;
    page.setFont(customFontRegular);
    page.drawText(summaryForm.comment, {
      x: pagePadding,
      y: formStartY,
      maxWidth: width - 2 * pagePadding,
      color: rgb(0, 0, 0),
    });
  }

  // Now, for each machine, create a page and draw machine details and drum configuration
  for (let machineIndex = 0; machineIndex < machinesForms.length; machineIndex++) {
    const machineForm = machinesForms[machineIndex];
    const drumConfiguration = drumConfigurations[machineIndex];

    // Function to determine if a field is applicable
    const isFieldApplicable = (machineForm, fieldName) => {
      switch (fieldName) {
        case 'version':
          // 'version' is not applicable for 'C240' machines
          return machineForm.machineType !== 'C240';
        case 'drumScanner':
          // 'drumScanner' is only applicable for 'D810' PRO version, excluding 'Neo' variants
          return (
            machineForm.version === 'PRO' &&
            machineForm.machineType === 'D810' &&
            !machineForm.machineType.includes('Neo')
          );
        case 'logoOnDisplay':
          // 'logoOnDisplay' is applicable unless version is 'OEM' or machine is 'C240' or not selected
          return (
            machineForm.version !== 'OEM' &&
            machineForm.machineType !== 'C240' &&
            machineForm.machineType !== ''
          );
        case 'barcodeReaderOption':
          // 'barcodeReaderOption' is not applicable for 'C240' and Neo machines
          return machineForm.machineType !== 'C240' && !machineForm.machineType.includes('Neo') && machineForm.version !== 'OEM';
        case 'barcodeReaderType':
          // 'barcodeReaderType' is applicable for Neo machines or when barcodeReaderOption is 'yes'
          return (machineForm.machineType.includes('Neo') && machineForm.version !== 'OEM') ||
                 (machineForm.barcodeReaderOption === 'yes' && !machineForm.machineType.includes('Neo') && machineForm.version !== 'OEM');
        case 'panelSide':
        case 'plexiglassWindows':
          // 'panelSide' and 'plexiglassWindows' are only applicable for 'L40' machines
          return ['L40', 'L40 ME', 'L40 DE'].includes(machineForm.machineType);
        case 'rightDoorDetail':
        case 'leftDoorDetail':
        case 'rightCaseDetail':
        case 'leftCaseDetail':
        case 'backCaseDetail':
        case 'topCaseDetail':
          // These fields are only applicable when color is 'nonstandardcolor'
          return machineForm.color === 'nonstandardcolor' &&
          !['C240', 'T10', 'T15 Neo'].includes(machineForm.machineType);
        default:
          // All other fields are applicable by default
          return true;
      }
    };

    // Prepare machine fields
    const machineFieldsArray = [];

    formFields['machines'].forEach((fieldName) => {
      if (isFieldApplicable(machineForm, fieldName)) {
        let fieldValue = machineForm[fieldName];

        // Handle translatable fields
        if (translatableFields.includes(fieldName)) {
          fieldValue = getTranslation(`machines.data`, fieldValue);
        }

        machineFieldsArray.push({
          label: translations['machines'].label[fieldName],
          value: fieldValue,
          translationKey: `machines.label.${fieldName}`,
        });
      }
    });

    const machineFields = { machines: machineFieldsArray };

    const machinePage = pdfDoc.addPage();
    machinePage.setFont(customFontRegular);
    machinePage.setFontSize(fontSize);
    const { width: machinePageWidth, height: machinePageHeight } = machinePage.getSize();

    drawHeaderElements(machinePage, machinePageHeight, logoImage, logoDims, machinePageWidth, pagePadding, customFontRegular, translations);

    let machineFormStartY = machinePageHeight - pagePadding - 30;

    machineFormStartY -= cellHeight;

    // Draw machine details in table
    machineFormStartY = drawFieldsForForm(
      'machines',
      machinePage,
      machineFormStartY,
      cellHeight,
      machinePageWidth,
      machineFields,
      (machinePageWidth - 2 * pagePadding) / 2,
      machineIndex
    );

    // Draw drum configuration below machine details
    const shouldShowConfigurator = (machineType) => {
      return machineType !== 'T10' && machineType !== 'T15 Neo' && machineType !== 'C240' && machineType !== '';
    };

    if (shouldShowConfigurator(machineForm.machineType)) {
      machinePage.setFontSize(fontSize - 2);

      const { rows, columns, columnsPerRow, mergedRows, mergedRowsLeft, mergedRowsRight } = drumConfiguration;

      const tableWidth = machinePageWidth - 2 * pagePadding;
      const drumCellHeight = 16;
      let currentY = machineFormStartY - 30;

      // Draw header row (column numbers)
      const grayScale = 0.4;
      const grayScaleFont = 0.8;

      if (columns !== 2) {
        for (let i = 0; i < columns; i++) {
          const cellX = pagePadding + (tableWidth / columns) * i;

          machinePage.drawText(`${i + 1}`, {
            x: cellX + 1.5,
            y: currentY + drumCellHeight - 14,
            color: rgb(grayScale, grayScale, grayScale),
          });

          // Draw cell border for header
          const cell = {
            x: cellX,
            y: currentY + drumCellHeight - 16,
            width: tableWidth / columns,
            height: drumCellHeight / 2,
          };
          machinePage.drawRectangle({
            ...cell,
            borderColor: rgb(grayScaleFont, grayScaleFont, grayScaleFont),
            borderWidth: 1,
          });
        }
      }

      // Draw row indexes
      for (let i = 0; i < rows; i++) {
        const rowY = currentY - drumCellHeight * (i + 1) + 3;
        machinePage.drawText(`${i + 1}`, {
          x: pagePadding - 8,
          y: rowY + 3,
          color: rgb(grayScale, grayScale, grayScale),
        });

        // Draw cell border for row index
        const cell = {
          x: pagePadding - 10,
          y: rowY - 3,
          width: 10,
          height: drumCellHeight,
        };
        machinePage.drawRectangle({
          ...cell,
          borderColor: rgb(grayScaleFont, grayScaleFont, grayScaleFont),
          borderWidth: 1,
        });
      }

      // Drawing functions for drum cells
      const drawCellWithNoBottomBorder = ({ x, y, width, height }, page) => {
        const rightX = x + width;

        page.drawLine({
          start: { x, y },
          end: { x, y: y + height },
          thickness: 1,
          color: rgb(0, 0, 0),
        });

        page.drawLine({
          start: { x: rightX, y },
          end: { x: rightX, y: y + height },
          thickness: 1,
          color: rgb(0, 0, 0),
        });

        page.drawLine({
          start: { x: rightX, y: y + height },
          end: { x, y: y + height },
          thickness: 1,
          color: rgb(0, 0, 0),
        });
      };

      const drawCellWithNoTopBorder = ({ x, y, width, height }, page) => {
        const rightX = x + width;

        page.drawLine({
          start: { x, y },
          end: { x: rightX, y },
          thickness: 1,
          color: rgb(0, 0, 0),
        });

        page.drawLine({
          start: { x, y },
          end: { x, y: y + height },
          thickness: 1,
          color: rgb(0, 0, 0),
        });

        page.drawLine({
          start: { x: rightX, y: y + height },
          end: { x: rightX, y },
          thickness: 1,
          color: rgb(0, 0, 0),
        });
      };

      const drawCellWithNoTopBottomBorder = ({ x, y, width, height }, page) => {
        const rightX = x + width;

        page.drawLine({
          start: { x, y },
          end: { x, y: y + height },
          thickness: 1,
          color: rgb(0, 0, 0),
        });

        page.drawLine({
          start: { x: rightX, y },
          end: { x: rightX, y: y + height },
          thickness: 1,
          color: rgb(0, 0, 0),
        });
      };

      const drawLeftCell = ({ x, y, width, height }, mergedAbove, mergedBelow, page) => {
        const rightX = x + width;

        page.drawLine({
          start: { x, y },
          end: { x, y: y + height },
          thickness: 1,
          color: rgb(0, 0, 0),
        });

        if (!mergedBelow) {
          page.drawLine({
            start: { x: rightX, y },
            end: { x: rightX, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
        }

        if (!mergedAbove) {
          page.drawLine({
            start: { x: rightX, y: y + height },
            end: { x, y: y + (mergedAbove ? 0 : height) },
            thickness: mergedAbove ? 0 : 1,
            color: rgb(0, 0, 0),
          });
        }
      };

      const drawRightCell = ({ x, y, width, height }, mergedAbove, mergedBelow, isLastCell, page) => {
        const rightX = x + width;

        if (!mergedAbove) {
          page.drawLine({
            start: { x, y },
            end: { x, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
        }

        page.drawLine({
          start: { x: rightX, y },
          end: { x: rightX, y: y + height },
          thickness: 1,
          color: rgb(0, 0, 0),
        });

        if (isLastCell) {
          page.drawLine({
            start: { x: pagePadding, y: y },
            end: { x: rightX, y: y },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
        }

        if (!mergedBelow) {
          page.drawLine({
            start: { x: rightX, y: y + height },
            end: { x, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
        }
      };

      // Draw each row
      let drumCurrentY = currentY - drumCellHeight;
      for (let i = 0; i < rows; i++) {
        let numCols = columnsPerRow[i];
        if (i > 0) {
          numCols = mergedRows[i] ? columnsPerRow[i + 1] : columnsPerRow[i];
        }

        for (let j = 0; j < numCols; j++) {
          const cellWidthCurrent = tableWidth / numCols;
          const cell = {
            x: pagePadding + cellWidthCurrent * j,
            y: drumCurrentY,
            width: cellWidthCurrent,
            height: drumCellHeight,
          };
          const isLastCell = i === rows - 1 && j === numCols - 1 && j % 2 === 1;

          if (columns === 2) {
            if (j === 0) {
              // Left column
              drawLeftCell(cell, mergedRowsLeft[i - 1], mergedRowsLeft[i], machinePage);
            } else if (j === 1) {
              // Right column
              drawRightCell(cell, mergedRowsRight[i - 1], mergedRowsRight[i], isLastCell, machinePage);
            }
          } else {
            if (mergedRows[i]) {
              if (mergedRows[i - 1]) {
                drawCellWithNoTopBottomBorder(cell, machinePage);
              } else {
                drawCellWithNoBottomBorder(cell, machinePage);
              }
            } else if (mergedRows[i - 1]) {
              drawCellWithNoTopBorder(cell, machinePage);
            } else {
              machinePage.drawRectangle({
                ...cell,
                borderColor: rgb(0, 0, 0),
                borderWidth: 1,
              });
            }
          }
        }
        drumCurrentY -= drumCellHeight;
      }
    }
  }

  const pdfBytes = await pdfDoc.save();
  download(pdfBytes, 'Order Summary.pdf', 'application/pdf');
};

// Create a function to draw header elements (logo and text)
const drawHeaderElements = (page, pageHeight, logoImage, logoDims, width, pagePadding, customFontRegular, translations) => {
  // Draw logo
  page.drawImage(logoImage, {
    x: pagePadding,
    y: pageHeight - pagePadding - logoDims.height,
    width: logoDims.width,
    height: logoDims.height,
  });

  // Draw header text
  page.setFont(customFontRegular);
  page.setFontSize(10);
  page.drawText(translations['pdf']['header']['orderForm'], {
    x: width - pagePadding - 175,
    y: pageHeight - pagePadding - 15,
    color: rgb(132 / 255, 132 / 255, 132 / 255),
  });
};
