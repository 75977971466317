import React from "react";
import { useTranslation } from "react-i18next";
import { Radio } from "@mui/material";
import "./RadioButtons.css";

const RadioImagesGroup = ({ id, machineType, handleChange, isValid = true }) => {
  const { t } = useTranslation();

  const models = [
	{ value: "D810 Neo", image: "/D810 Neo.png", label: "D810 Neo" },
    { value: "D1080 Neo", image: "/D1080 Neo.png", label: "D1080 Neo" },
    { value: "D540 Neo", image: "/D540 Neo.png", label: "D540 Neo" },
	{ value: "D810", image: "/D810.png", label: "D810" },
    { value: "D1080", image: "/D1080.png", label: "D1080" },
    { value: "L40", image: "/L40.png", label: "L40" },
    { value: "C240", image: "/C240.png", label: "C240" },
    { value: "T15 Neo", image: "/T15 Neo.png", label: "T15 Neo" },
    { value: "T10", image: "/T10.png", label: "T10" },
  ];

  const handleImageClick = (value) => {
    handleChange({ target: { name: id, value } });
  };

  return (
    <div className="radio-input-box image-radio-input">
      <div>
        {!isValid && (
          <span className="error-message">
            {t("global.validation.radio.required")}
        </span>
        )}
      </div>
	  <div className="model-grid">
        {models.map((model) => (
          <div
            key={model.value}
            className={`radio-with-image ${
              machineType === model.value ? "selected" : ""
            }`}
            onClick={() => handleImageClick(model.value)}
          >
            <img
              src={process.env.PUBLIC_URL + model.image}
              alt={model.label}
            />
            <span className="model-label">{model.label}</span>
            <Radio
              checked={machineType === model.value}
              value={model.value}
              name={id}
              id={id}
              onChange={handleChange}
              sx={{ display: "none" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioImagesGroup;
