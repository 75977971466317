import React from "react";
import { Checkbox, FormControlLabel, FormLabel } from "@mui/material";
import "./CheckboxesGroup.css";

const CheckboxesGroup = ({ label, options, values, handleChange }) => {

  return (
    <div className="checkboxes-group">
      {label && <FormLabel className="checkboxes-group-label">{label}</FormLabel>}
      <div className="checkboxes-container">
        {options.map((option) => (
          <div key={option.name} className="checkbox-item">
            <FormControlLabel
              control={
                <Checkbox
                  name={option.name}
                  checked={values[option.name]}
                  onChange={handleChange}
                />
              }
              label={<span style={{ color: '#000000', fontSize: '1.15rem',  }}>{option.label}</span>}
            />
            {option.description && (
              <div className="checkbox-description">{option.description}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckboxesGroup;
