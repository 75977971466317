import { useContext, useState, useEffect } from 'react';
import { generatePDF } from '../../services/pdfGenerator';
import { DrumConfigurationContext } from '../../context/DrumConfigurationContext';
import { FormContext } from '../../context/FormContext';
import { useTranslation } from "react-i18next";
import { scrollToTop } from "../../utility/smoothScroll";

const GeneratePDFButton = ({handleButtonClick, validateForm}) => {
    const { drumConfigurations } = useContext(DrumConfigurationContext);
    const { formData } = useContext(FormContext);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const { t } = useTranslation();
    const translations = {
        contact: {
            header: t("navbar.contactPage"),
            label: {
                name: t("page.contact.label.name"),
                company: t("page.contact.label.company"),
                address: t("page.contact.label.address"),
                nip: t("page.contact.label.nip"),
                phone: t("page.contact.label.phone"),
                email: t("page.contact.label.email")
            }
        },
        billing: {
            header: t("pdf.contact.header.billing"),
            label: {
                billingCompany: t("page.contact.label.name"),
                billingAddress: t("page.contact.label.address"),
                billingNip: t("page.contact.label.nip"),
            }
        },
        IDSBilling: {
            header: t("pdf.contact.header.billingIDS"),
            label: {
                IDSBillingCompany: t("page.contact.label.name"),
                IDSBillingAddress: t("page.contact.label.address"),
                IDSBillingNip: t("page.contact.label.nip"),
            }
        },
        installation: {
            headers: {
                installation:  t("page.installation.header.installation"),
                services: t('pdf.installation.header.services'),
                transfer: t('pdf.installation.header.transfer'),
                it: t("page.installation.header.it")
            },
            label: {
                company: t("page.installation.label.company"),
                address: t("page.installation.label.address"),
                phone: t("page.installation.label.phone"),
                name: t("page.installation.label.name"),
                country: t("page.installation.label.country"),
                industry: t("page.installation.label.industry"),
                installation: t("page.installation.label.installation"),
                configuration: t("page.installation.label.configuration"),
                database: t("page.installation.label.database"),
                productSchemes: t("page.installation.label.productSchemes"),
                machinesTraining: t("page.installation.label.machinesTraining"),
                webTraining: t("page.installation.label.webTraining"),
                serviceTraining: t("page.installation.label.serviceTraining"),
                internetConnection: t("pdf.installation.label.internetConnection"),
                networkType: t("page.installation.label.networkType"),
                preferredLogin: t("page.installation.label.preferredLogin"),
                cardReaderType: t("page.installation.label.cardType"),
                contactPerson: t("page.installation.label.contactPerson"),
                emailAddress: t("page.installation.label.email"),
                contactPersonPhone: t("page.installation.label.contactPersonPhone"),
                machineTransfer: t("page.installation.label.machineTransfer"),
                machineInstallation: t("page.installation.label.machineInstallation"),
                transport: t("page.installation.label.transport"),
                rentalPeriod: t("page.installation.label.rentalPeriod"),
                paymentMethod: t("page.installation.label.paymentMethod"),
                subscriptionCost: t("page.installation.label.subscriptionCost"),
                expectedDeliveryDate: t("page.installation.label.expectedDeliveryDate"),
                comment: t("page.installation.label.comment")
            },
            data: {
                new: t("page.installation.radio.new"),
                reinstall: t("page.installation.radio.reinstall"),
                sale: t("page.installation.radio.sale"),
                rental: t("page.installation.radio.rental"),
                transfer: t("page.installation.radio.transfer"),
                leasing: t("page.installation.radio.leasing"),
                asd: t("page.installation.radio.asd"),
                lan: t("page.installation.radio.lan"),
                gsm: t("page.installation.radio.gsm"),
                card: t("page.installation.radio.card"),
                login: t("page.installation.radio.login"),
                other: t("page.installation.radio.other"),
                unique: t("page.installation.radio.unique"),
                mifare: t("page.installation.radio.mifare"),
                static: t("page.installation.radio.static"),
                ownInstallation: t("page.installation.radio.ownInstallation"),
                ownTransport: t("page.installation.radio.ownTransport"),
                true: t("global.label.yes"),
                false: t("global.label.no"),
                nodatafound: t("global.label.nodatafound"),

                //industries
                other: t("industries.other"),
                electronics: t("industries.electronics"),
                metallurgy: t("industries.metallurgy"),
                food: t("industries.food"),
                shipbuilding: t("industries.shipbuilding"),
                construction: t("industries.construction"),
                power: t("industries.power"),
                medicine: t("industries.medicine"),
                mining: t("industries.mining"),
                chemical: t("industries.chemical"),
                logistics: t("industries.logistics"),
                aviation: t("industries.aviation"),
                automotive: t("industries.automotive"),
                economic: t("industries.economic"),
                printing: t("industries.printing"),
                light: t("industries.light"),
                machinery: t("industries.machinery"),
                fuel: t("industries.fuel"),
                glass: t("industries.glass"),
                agriculture: t("industries.agriculture"),
                furniture: t("industries.furniture"),
                trade: t("industries.trade"),
                public: t("industries.public"),
                railway: t("industries.railway"),

                //countries
                AL: t("countries.AL"),
                AD: t("countries.AD"),
                AM: t("countries.AM"),
                AT: t("countries.AT"),
                AZ: t("countries.AZ"),
                BY: t("countries.BY"),
                BE: t("countries.BE"),
                BA: t("countries.BA"),
                BG: t("countries.BG"),
                HR: t("countries.HR"),
                CY: t("countries.CY"),
                CZ: t("countries.CZ"),
                DK: t("countries.DK"),
                EE: t("countries.EE"),
                FO: t("countries.FO"),
                FI: t("countries.FI"),
                FR: t("countries.FR"),
                GE: t("countries.GE"),
                DE: t("countries.DE"),
                GR: t("countries.GR"),
                GI: t("countries.GI"),
                HU: t("countries.HU"),
                IS: t("countries.IS"),
                IE: t("countries.IE"),
                IT: t("countries.IT"),
                KZ: t("countries.KZ"),
                XK: t("countries.XK"),
                LV: t("countries.LV"),
                LI: t("countries.LI"),
                LT: t("countries.LT"),
                LU: t("countries.LU"),
                MT: t("countries.MT"),
                MD: t("countries.MD"),
                MC: t("countries.MC"),
                ME: t("countries.ME"),
                NL: t("countries.NL"),
                MK: t("countries.MK"),
                NO: t("countries.NO"),
                PL: t("countries.PL"),
                PT: t("countries.PT"),
                RO: t("countries.RO"),
                RU: t("countries.RU"),
                SM: t("countries.SM"),
                RS: t("countries.RS"),
                SK: t("countries.SK"),
                SI: t("countries.SI"),
                ES: t("countries.ES"),
                SE: t("countries.SE"),
                CH: t("countries.CH"),
                TR: t("countries.TR"),
                UA: t("countries.UA"),
                GB: t("countries.GB"),
                VA: t("countries.VA"),
            },
        },
        machines: {
            header: t("navbar.machinesPage"),
            label: {
                machineType: t("page.machines.header.machineType"),
                version: t("page.machines.label.version"),
                barcodeReader: t("page.machines.label.barcodeReader"),
                barcodeReaderOption: t("page.machines.label.barcodeReaderOption"),
                barcodeReaderType: t("page.machines.label.barcodeReaderType"),
                drumScanner: t("page.machines.label.drumScanner"),
                type: t("page.machines.label.type"),
                logoOnDisplay: t("page.machines.label.logoOnDisplay"),
                color: t("page.machines.label.color"),
                rightDoorDetail: t("page.machines.checkbox.rightDoorColor"),
                leftDoorDetail: t("page.machines.checkbox.leftDoorColor"),
                rightCaseDetail: t("page.machines.checkbox.rightCaseDesign"),
                leftCaseDetail: t("page.machines.checkbox.leftCaseDesign"),
                backCaseDetail: t("page.machines.checkbox.backCaseDesign"),
                topCaseDetail: t("page.machines.checkbox.topCaseDesign"),
                panelSide: t("page.machines.label.panelSide"),
                plexiglassWindows: t("page.machines.checkbox.plexiglassWindows")
            },
            data: {
                standard: t("page.machines.radio.standard"),
                custom: t("page.machines.radio.custom"),
                dynamic: t("page.machines.radio.dynamic"),
                uncertain: t("page.machines.radio.uncertain"),
                standardcolor: t("page.machines.radio.standardColor"),
                nonstandardcolor: t("page.machines.radio.nonstandardColor"),
                standardReader: t("page.machines.radio.standardReader"),
                customReader: t("page.machines.radio.customReader"),
                yes: t("global.label.yes"),
                no: t("global.label.no"),
                nodatafound: t("global.label.nodatafound"),
                left: t("page.machines.radio.leftSide"),
                right: t("page.machines.radio.rightSide")
            },
        },
        summary: {
            label: {
                comment: t("page.summary.label.comment")
            }
        },
        pdf: {
            header: {
                orderForm: t("header.title")
            }
        }
    };


    useEffect(() => {
        if (isButtonClicked) {
            generatePDF(drumConfigurations, formData, translations);
            setIsButtonClicked(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    const handleDownloadPDF = () => {
        if (!validateForm()) {
            scrollToTop();
            return;
        }

        handleButtonClick();
        setIsButtonClicked(true);
    }

    return (
        <button className="btn-next" style={{ width: '50%' }} onClick={handleDownloadPDF}>{t("global.btn.downloadPDF")}</button>
    )
};

export default GeneratePDFButton;
